@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;900&family=Prompt&display=swap');

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  overflow: hidden;
}

#container_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  font-family: 'Prompt', sans-serif;
}

.contact {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1.5em;
}

a {
  font-style: normal;
}

.color_white {
  color: #FFFFFF;
}

.typewriter {
  overflow: hidden;
  border-right: .15em solid #FFFFFF;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .30em;
  animation: 
    typing 3.5s steps(25, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #FFFFFF; }
}

@media only screen and (max-width: 600px) {
  #content {
    font-size: medium;
  }
}